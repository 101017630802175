<template>
  <header v-if="visible">
    <title>{{ GLOBAL.NAME }} | v.{{ GLOBAL.VERSION }}</title>
    <!-- Navbar -->
    <nav :class="navbarClasses">
      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">
        <li>
          <router-link to="/home">
            <button class="btn txt-white">
              <i class="fa-solid fa-house"></i>
            </button>
          </router-link>
        </li>

        <!-- Profile -->
        <li class="nav-item dropdown user-menu">
          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
            <img
              src="dist/img/user2-160x160.jpg"
              class="user-image img-circle elevation-1"
              alt="User Image"
            />
            <span class="d-none d-md-inline">{{
              this.person != null ? this.person.nombres : ""
            }}</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <!-- User image -->
            <li
              :class="['user-header', color]"
              style="border: 1px solid #ffffff"
            >
              <img
                src="dist/img/user2-160x160.jpg"
                class="img-circle elevation-2"
                alt="User Image"
              />
              <p>
                {{ this.person != null ? this.person.nombres : "" }}
                <small>
                  {{ this.person != null ? this.person.nombre : "" }}
                </small>
              </p>
            </li>

            <!-- Menu Footer-->
            <button @click="logout" class="btn btn-primary">SALIR</button>
          </ul>
        </li>
      </ul>
    </nav>
    <!-- /.navbar -->
    <MenuView />
  </header>
</template>

<script>
import router from "../router";
import GLOBAL from "../Global.js";
import MenuView from "@/components/Menu.vue";

export default {
  components: {
    MenuView,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      GLOBAL,
      menu: [],
      menu_eds: [],
      menu_financiera: [],
      navbarClasses: "", // Inicializamos con una cadena vacía
      color: "",
    };
  },
  updated() {
    this.updateNavbarClasses();
  },
  mounted() {
    this.reload();
  },
  created() {
    this.updateNavbarClasses();

    if (typeof this.$route.params.data === "undefined") {
      this.person = JSON.parse(sessionStorage.getItem(GLOBAL.SESSION_KEY));
    } else {
      this.person = JSON.parse(this.$route.params.data);
    }

    if (this.person == null) {
      router.push("/");
    }
  },
  computed: {
    visible() {
      this.reload();
      const publicas = ["login", "logout"];
      if (publicas.includes(this.$route.name)) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {},
  methods: {
    updateNavbarClasses() {
      const user = JSON.parse(sessionStorage.getItem(GLOBAL.SESSION_KEY));
      var perfilUsuario = 3;
      try {
        perfilUsuario = parseInt(user.perfil_id);
        GLOBAL.PERFIL = perfilUsuario;
      } catch (error) {
        console.log("Sin reconocimiento de perfil");
      }

      switch (perfilUsuario) {
        case 1:
        case 2:
        case 3:
          this.color = "bg-sabaq";
          break;
      }

      //main-header navbar navbar-expand navbar-white navbar-light
      this.navbarClasses = [
        "main-header",
        "navbar",
        "navbar-expand-md",
        "navbar-white",
        this.color,
        "navbar-dark",
      ].join(" "); // Convertimos la matriz en una cadena con clases separadas por espacios
    },

    reload() {
      if (typeof this.$route.params.data === "undefined") {
        this.person = JSON.parse(sessionStorage.getItem(GLOBAL.SESSION_KEY));
      } else {
        this.person = JSON.parse(this.$route.params.data);
      }

      if (this.person != null) {
        var perfil = parseInt(this.person.perfil_id);
        switch (perfil) {
          case 1:
          case 2:
            this.menu = GLOBAL.MENU_DC50;
            break;
        }
      }
    },

    logout() {
      sessionStorage.removeItem(GLOBAL.SESSION_KEY);
      router.push({ name: "login" });
    },
  },
};
</script>

<style scoped>
.header {
  height: 50px;
  background-color: rgb(255, 217, 0);
}

.nav-link {
  color: rgb(195, 195, 195);
}

.nav-pills .nav-link:not(.active):hover {
  color: rgb(0, 255, 0);
}

.user-image {
  border: 1px solid #fff;
}

.txt-white {
  color: #fff;
}
</style>
