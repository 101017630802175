<template>
    <div id="printableArea">
        <div v-for="persona in personas" :key="persona.id">
            <div v-for="(informe, key) in persona.informes" :key="informe">
                <div v-if="calculateTotal(Object.values(informe.meses), 'horas') > 0">
                    <div class="card1" v-if="key < 2024">

                        <div class="grupo">G{{ persona.grupos_id  }}</div>
                        <div class="card1-titulo">REGISTRO DE PUBLICADOR DE LA CONGREGACIÓN</div>

                        <table width="100%">
                            <tr class="card1-data">
                                <td colspan="2">
                                    <span class="text-bold">Nombre:</span> {{ persona.nombres }}
                                </td>
                            </tr>
                            <tr class="card1-data">
                                <td width="70%">
                                    <span class="text-bold">Fecha de Nacimiento: </span>
                                    {{ persona.fecha_nacimiento }}
                                    <span v-if="persona.edad_actual">, ({{ persona.edad_actual }} años)</span>
                                </td>
                                <td class="text-bold">
                                    <table width="100%">
                                        <tr>
                                            <td width="60%">
                                                <div class="check" :class="{ 'checked': persona.genero === 'H' }"></div>
                                                Hombre
                                            </td>
                                            <td>
                                                <div class="check" :class="{ 'checked': persona.genero === 'M' }"></div>
                                                Mujer
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr class="card1-data">
                                <td width="70%">
                                    <span class="text-bold">Fecha de Bautismo: </span>
                                    {{ persona.fecha_bautismo }}
                                    <span v-if="persona.bautismo">
                                        , hace {{ persona.bautismo }} años
                                    </span>
                                </td>
                                <td class="text-bold">
                                    <table width="100%">
                                        <tr>
                                            <td width="60%">
                                                <div class="check" :class="{ 'checked': persona.ungido !== 'S' }"></div>
                                                Otras ovejas
                                            </td>
                                            <td>
                                                <div class="check" :class="{ 'checked': persona.ungido === 'S' }"></div>
                                                Ungido
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr class="card1-data text-right text-bold">
                                <td colspan="2">
                                    <div class="check" :class="{ 'checked': persona.anciano === 'S' }"></div>
                                    Anciano
                                    <div class="check" :class="{ 'checked': persona.ministerial === 'S' }"></div>
                                    Siervo ministerial
                                    <div class="check" :class="{ 'checked': persona.regular === 'S' }"></div>
                                    Precursor Regular
                                </td>
                            </tr>
                        </table>

                        <table class="card1-table" width="100%">
                            <tbody>
                                <tr>
                                    <th width="90px" class="card1-th">
                                        <div class="card1-mini">Año de servicio </div>
                                        <div class="card1-year">{{ key }}</div>
                                    </th>
                                    <th width="60px" class="card1-th">Publicaciones</th>
                                    <th width="60px" class="card1-th">Videos</th>
                                    <th width="50px" class="card1-th">Horas</th>
                                    <th width="50px" class="card1-th">Revisitas</th>
                                    <th width="50px" class="card1-th">Cursos</th>
                                    <th class="card1-th">Notas</th>
                                </tr>
                                <tr v-for="(mes, k) in informe.meses" :key="mes">
                                    <td class="text-bold">{{ k }}</td>
                                    <td>{{ mes.publicaciones == 0 ? "" : mes.publicaciones }}</td>
                                    <td>{{ mes.videos == 0 ? "" : mes.videos }}</td>
                                    <td>{{ mes.horas == 0 ? "" : mes.horas }}</td>
                                    <td>{{ mes.revisitas == 0 ? "" : mes.revisitas }}</td>
                                    <td>{{ mes.cursos == 0 ? "" : mes.cursos }}</td>
                                    <td class="text-left text-nota">{{ mes.notas }}</td>
                                </tr>
                                <tr>
                                    <td class="text-bold">Totales</td>
                                    <td>{{ calculateTotal(Object.values(informe.meses), 'publicaciones') }}</td>
                                    <td>{{ calculateTotal(Object.values(informe.meses), 'videos') }}</td>
                                    <td>{{ calculateTotal(Object.values(informe.meses), 'horas') }}</td>
                                    <td>{{ calculateTotal(Object.values(informe.meses), 'revisitas') }}</td>
                                    <td>{{ calculateTotal(Object.values(informe.meses), 'cursos') }}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td class="text-bold">Promedio</td>
                                    <td>{{ calculateAverage(Object.values(informe.meses), 'publicaciones') }}</td>
                                    <td>{{ calculateAverage(Object.values(informe.meses), 'videos') }}</td>
                                    <td>{{ calculateAverage(Object.values(informe.meses), 'horas') }}</td>
                                    <td>{{ calculateAverage(Object.values(informe.meses), 'revisitas') }}</td>
                                    <td>{{ calculateAverage(Object.values(informe.meses), 'cursos') }}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="card2" v-else>
                        <div class="grupo">G{{ persona.grupos_id  }}</div>
                        <div class="card1-titulo">REGISTRO DE PUBLICADOR DE LA CONGREGACIÓN</div>

                        <table width="100%">
                            <tr class="card2-data">
                                <td colspan="2">
                                    <span class="text-bold">Nombre:</span> {{ persona.nombres }}
                                </td>
                            </tr>
                            <tr class="card2-data">
                                <td width="70%">
                                    <span class="text-bold">Fecha de Nacimiento: </span>
                                    {{ persona.fecha_nacimiento }}
                                    <span v-if="persona.edad_actual">, ({{ persona.edad_actual }} años)</span>
                                </td>
                                <td class="text-bold">
                                     <div class="check" :class="{ 'checked': persona.genero === 'H' }"></div>
                                     <div class="checklbl">Hombre</div>
                                    
                                     <div class="check" :class="{ 'checked': persona.genero === 'M' }"></div>
                                     <div class="checklbl">Mujer</div>
                                </td>
                            </tr>
                            <tr class="card2-data">
                                <td width="70%">
                                    <span class="text-bold">Fecha de Bautismo: </span>
                                    {{ persona.fecha_bautismo }}
                                    <span v-if="persona.bautismo">
                                        , hace {{ persona.bautismo }} años
                                    </span>
                                </td>
                                <td class="text-bold">
                                    <div class="check" :class="{ 'checked': persona.ungido !== 'S' }"></div>
                                    <div class="checklbl">Otras ovejas</div>

                                    <div class="check" :class="{ 'checked': persona.ungido === 'S' }"></div>
                                    <div class="checklbl">Ungido</div>
                                </td>
                            </tr>
                            <tr class="card2-data text-bold">
                                <td colspan="2">

                                    <table width="100%">
                                        <tr>
                                            <td class="card2-label">
                                                <div class="check" :class="{ 'checked': persona.anciano === 'S' }">
                                                </div>
                                                Anciano
                                            </td>
                                            <td class="card2-label text-center">
                                                <div class="check" :class="{ 'checked': persona.ministerial === 'S' }">
                                                </div>
                                                Siervo ministerial
                                            </td>
                                            <td class="card2-label text-center">
                                                <div class="check" :class="{ 'checked': persona.regular === 'S' }">
                                                </div>
                                                Precursor Regular
                                            </td>
                                            <td class="card2-label text-center">
                                                <div class="check" :class="{ 'checked': persona.especial === 'S' }">
                                                </div>
                                                Precursor especial
                                            </td>
                                            <td class="card2-label text-right">
                                                <div class="check" :class="{ 'checked': persona.misionero === 'S' }">
                                                </div>
                                                Misionero que sirve <br />en el campo
                                            </td>
                                        </tr>
                                    </table>


                                </td>
                            </tr>
                        </table>

                        <table class="card2-table" width="100%">
                            <tr>
                                <th width="90px" class="card1-th">
                                    <div class="card1-mini">Año de servicio </div>
                                    <div class="card1-year">{{ key }}</div>
                                </th>
                                <th width="60px" class="card2-th">
                                    <div class="card2-bold">Participación</div>
                                    <div class="card2-bold">en el</div>
                                    <div class="card2-bold">ministerio</div>
                                </th>
                                <th width="60px" class="card2-th">
                                    <div class="card2-bold">Cursos</div>
                                    <div class="card2-bold">bíblicos</div>
                                </th>
                                <th width="50px" class="card2-th">
                                    <div class="card2-bold">Precursor</div>
                                    <div class="card2-bold">auxiliar</div>
                                </th>
                                <th width="110px" class="card2-th">
                                    <div class="card2-bold">Horas</div>
                                    <div class="card2-normal">(Si es precursor o</div>
                                    <div class="card2-normal">misionero que</div>
                                    <div class="card2-normal">sirve en el campo)</div>
                                </th>
                                <th class="card2-th">Notas</th>
                            </tr>
                            <tr v-for="(mes, k) in informe.meses" :key="mes">
                                <td class="text-bold">{{ k }}</td>
                                <td class="fackeck">
                                    <span v-if="mes.informo == 'S'">
                                        <div class="check checked"></div>
                                    </span>
                                </td>
                                <td>{{ mes.cursos == 0 ? "" : mes.cursos }}</td>
                                <td>{{ mes.pauxiliar='S' ? "" : "SI" }}</td>
                                <td>{{ mes.horas <= 1 ? "" : mes.horas }}</td>
                                <td class="text-left text-nota">{{ mes.notas }}</td>
                            </tr>
                            <tr>
                                <td colspan="3" class="td-noborder"></td>
                                <td class="text-bold">Total</td>
                                <td>{{  persona.regular === 'S'? calculateTotal(Object.values(informe.meses), 'horas') : "" }}</td>
                                <td></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    props: {
        personas: {
            type: Array,
            required: true
        }
    },
    methods: {
        calculateTotal(meses, field) {
            return meses.reduce((sum, mes) => sum + (mes[field] || 0), 0);
        },

        calculateAverage(meses, field) {
            const total = this.calculateTotal(meses, field);
            return (total / meses.length).toFixed(2); // Redondea el promedio a dos decimales
        }
    }
};
</script>