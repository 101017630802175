var SERVER = "";

let hostname = location.hostname.toLowerCase();
switch (hostname) {
    case "localhost":
        SERVER = "http://localhost:8080";
        break;
    case "informestj.com":
    case "sabaq.informestj.com":
        SERVER = "https://sabaqws.informestj.com";
        break;
    case "192.168.101.66":
        SERVER = "http://192.168.101.66:8080";
        break;
    default:
        SERVER = "https://sabaqws.informestj.com";
        break;
}


module.exports = {
    PERFIL: 1,
    LOGIN: false,
    USER: null,
    VERSION: "2.0.0",
    NAME: "InformesJ",
    SESSION_KEY: "sinfows.persona",
    COLOR_PERFIL: {
        1: "bg-sabaq",
    },

    PATH: SERVER,
    API_GET_LOGIN: SERVER + '/sinfows/api/v2/login',

    MENU_ITEMS: [
        {
            descripcion: 'Home',
            to: '/home',
            icon: 'fa-solid fa-house menuitem',
            allowedProfiles: [1, 2, 5, 8] // Solo perfiles que pueden ver "Home"
        }, 
        {
            descripcion: 'Tarjetas',
            to: '/tarjetas',
            icon: 'fa-solid fa-users menuitem',
            allowedProfiles: [1, 2] // Solo perfiles 1 y 2
        },
        {
            descripcion: 'Resumen de datos',
            to: '/resumen',
            icon: 'fa-solid fa-earth-americas menuitem',
            allowedProfiles: [1, 2] // Solo perfiles 1 y 2
        },
        {
            descripcion: 'Gestión de Usuarios',
            to: '/usuarios',
            icon: 'fa-solid fa-users menuitem',
            allowedProfiles: [1, 2] // Solo perfiles 1 y 2
        }
    ],

}


