<template>
  <div class="layout">

    <h2>
      Gestion de Usuarios
      <button class="btn bg-blue btn-sm" @click="nuevoRegistro">
        <i class="fa-solid fa-plus"></i>
      </button>
    </h2>

    <table class="table table-striped" width="100%">
      <tr>
        <td colspan="3">Palabra clave busqueda</td>
      </tr>
      <tr>
        <td>
          <input class="form-control uppercase-input" v-model="filtro_mensaje" @keyup.enter="cargarUsuarios">
        </td>
        <td>
          <select class="form-control" v-model="filtro_estado">
            <option value=true>ACTIVO</option>
            <option value=false>INACTIVO</option>
          </select>
        </td>
        <td width="5px">
          <button class="btn btn-success" @click="cargarUsuarios">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </td>
      </tr>
    </table>

    <table class="table table-striped" width="100%">

      <tr>
        <td colspan="2">Lista de usuarios</td>
      </tr>

      <tr v-for="(items, index) in registros" :key="index">
        <td>
          <div class="usuario-line flex-center">
            <span class="usuarios-nombres text-bold">
              {{ items.nombres.toUpperCase() }} {{ items.apellidos.toUpperCase() }}
            </span>

            <div v-if="items.usuario">
              <span v-if="items.usuario.perfil_id == 2" class="isadmin">
                <i class="fa-solid fa-key"></i>
              </span>
            </div>
            <div v-else>
              <span class="text-red text-bold">
                &nbsp;<i class="fa-solid fa-triangle-exclamation"></i>
              </span>
            </div>

            <span v-if="!items.activo" class="isinactivo badge bg-danger">
              INACTIVO
            </span>
          </div>

          <div class="usuarios-datos">
            <div>
              <div class="usuario-info">
                <i class="fa-solid fa-mobile-screen-button"></i>
                <span class="usuario-telefono">{{ items.telefono }}</span>
              </div>
              <div class="usuario-info" v-if="items.correo">
                <i class="fa-solid fa-at"></i>
                <span class="usuario-telefono">{{ items.correo }}</span>
              </div>
              <div class="usuario-info" v-if="items.direccion">
                <i class="fa-solid fa-house"></i>
                <span class="usuario-telefono">{{ items.direccion }}</span>
              </div>
            </div>
          </div>
        </td>

        <td width="5px">
          <button class="btn bg-purple" @click="seleccionaUsuario(items)">
            <i class="fa-solid fa-pencil"></i>
          </button>
        </td>
      </tr>
    </table>

    <!-- Modal -->
    <div class="modal" tabindex="-1" :class="{ 'is-active': showModal }">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Datos</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table width="100%">
              <tr>
                <td>
                </td>
                <td>
                  <select class="sm form-control" v-model="estado">
                    <option value=true>ACTIVO</option>
                    <option value=false>INACTIVO</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label-datos">Nombre</div>
                  <input class="form-control uppercase-input sm" v-model="nombres" autocomplete="one-time-code" />
                </td>
                <td>
                  <div class="label-datos">Apellidos</div>
                  <input class="form-control uppercase-input sm" v-model="apellidos" autocomplete="one-time-code" />
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="label-datos">Telefono</div>
                  <input class="form-control sm" v-model="telefono" autocomplete="one-time-code" />
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="label-datos">Correo</div>
                  <input class="form-control lowercase-input sm" v-model="correo" autocomplete="one-time-code" />
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="label-datos">Dirección</div>
                  <input class="form-control sm" v-model="direccion" autocomplete="one-time-code" />
                </td>
              </tr>
            </table>
            <hr />

            <div>
              <table width="100%">
                <tr>
                  <td width="50%">
                    <div class="label-datos">Usuario</div>
                    <input class="form-control uppercase-input sm" v-model="usuario" autocomplete="one-time-code" />
                  </td>
                  <td>
                    <div class="label-datos">Tipo de acces</div>
                    <select class="sm form-control" v-model="perfil">
                      <option value="3">VOLUNTARIO</option>
                      <option value="2">SUPERVISOR</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">

                    <table width="100%">
                      <tr>
                        <td>
                          <div class="label-datos">Contraseña</div>
                          <input type="password" class="form-control sm" v-model="contrasenia"
                            autocomplete="one-time-code" :disabled="bloqueoPassword" />
                        </td>
                        <td width="5px" style="vertical-align: bottom;   padding: 0px 0px 10px 0px;">
                          <button class="btn btn-sm bg-gray" @click="desbloquePassword">
                            <i class="fa-solid fa-key"></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" style="font-size: 14px; text-align: right;">
                          Para cambiar la clave presione la llave
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

              </table>
            </div>



          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal"
              :disabled="cargando">Cancelar</button>
            <button type="button" class="btn btn-primary" :disabled="cargando" @click="guardarRegistro">Guardar</button>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
import axios from 'axios';
import md5 from "md5";
import router from "../router";
import GLOBAL from "../Global.js";

export default {
  data() {
    return {
      GLOBAL,
      turno: 0,
      turno_unico: "",
      novedad: "",
      mensaje: "",
      cargando: false,
      enviando: false,
      registros: [],
      filtro_mensaje: "",
      filtro_estado: true,
      identificacion: '',
      nombres: '',
      apellidos: '',
      telefono: '',
      direccion: '',
      correo: '',
      usuario: '',
      clave: '',
      contrasenia: '',
      perfil: 0,
      showModal: false,
      id: 0,
      estado: true,
      bloqueoPassword: true
    }
  },

  created() {
    this.person = JSON.parse(sessionStorage.getItem(GLOBAL.SESSION_KEY));
    this.cargarUsuarios()
    console.log(this.person.token)
  },

  methods: {
    desbloquePassword() {
      this.bloqueoPassword = !this.bloqueoPassword
    },
    closeModal() {
      this.showModal = false;
    },

    nuevoRegistro() {
      this.id = 0
      this.estado = true
      this.identificacion = ""
      this.nombres = ""
      this.apellidos = ""
      this.telefono = ""
      this.correo = ""
      this.direccion = ""
      this.usuario = ""
      this.contrasenia = ""
      this.perfil = 3
      this.showModal = true;
      this.bloqueoPassword = false
    },

    seleccionaUsuario(registro) {
      this.showModal = true;
      this.id = registro.id
      this.estado = registro.activo
      this.identificacion = registro.identificacion
      this.nombres = registro.nombres.toUpperCase()
      this.apellidos = registro.apellidos.toUpperCase()
      this.telefono = registro.telefono
      this.correo = registro.correo
      this.direccion = registro.direccion
      this.usuario = registro.usuario.nombre_usuario.toLowerCase()
      this.clave = registro.usuario.contrasena
      this.perfil = registro.usuario.perfil_id
      this.contrasenia = ''
      this.bloqueoPassword = true
    },

    async cargarUsuarios() {
      try {
        let url = GLOBAL.API_GET_PERSONA
          + '?contiene=' + this.filtro_mensaje
          + '&estado=' + this.filtro_estado;

        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Authorization": "Bearer " + this.person.token
          }
        });
        this.registros = response.data;
      } catch (error) {
        console.error('Error al cargar registros:', error);
      }
    },

    async guardarRegistro() {
      try {
        this.cargando = true;
        let url = GLOBAL.API_GET_PERSONA;

        this.usuario = this.usuario.toUpperCase();
        let request = {
          id: this.id,
          identificacion: this.identificacion,
          nombres: this.nombres,
          apellidos: this.apellidos,
          telefono: this.telefono,
          correo: this.correo,
          direccion: this.direccion,
          usuario: this.usuario,
          estado: this.estado,
          perfil: this.perfil,
          fecha_nacimiento: null,
          contrasenia: this.contrasenia != '' ? md5(this.contrasenia) : null,
        };


        if (this.id == 0) {
          await axios.post(url, request, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Authorization": "Bearer " + this.person.token
            }
          });
        } else {
          await axios.put(url, request, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Authorization": "Bearer " + this.person.token
            }
          });
        }

        if (this.person.id == this.id) {
          console.log('Eres tu mismo')
          if (this.contrasenia != '') {
            console.log("no cambio de usuario")
            this.person.token = btoa(this.usuario + ":" + md5(this.contrasenia))
          } else {
            console.log("cambio de usuario")
            this.person.token = btoa(this.usuario + ":" + this.clave)
          }
          sessionStorage.setItem(GLOBAL.SESSION_KEY, JSON.stringify(this.person));
        }

        this.showModal = false;
        this.id = 0
        this.identificacion = ""
        this.nombres = ""
        this.apellidos = ""
        this.telefono = ""
        this.correo = ""
        this.direccion = ""
        this.usuario = ""

        this.cargarUsuarios();

        // Mostrar mensaje de éxito
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });



        Toast.fire({
          icon: "success",
          title: "Se ha actualizado con éxito"
        });


      } catch (error) {
        console.error('Error al cargar registros:', error);
      } finally {
        this.cargando = false;
      }
    },

  }
};
</script>

<style scoped>
.usuario-line {
  display: flex;
  align-items: center;
  /* Centra verticalmente */
}

.isadmin,
.isinactivo {
  margin-left: 8px;
  font-size: 9px;
}

.isadmin {
  color: rgb(224, 162, 4);
  font-weight: 400;
}

.isadmin i {
  font-size: 20px;
  margin-bottom: 5px !important;
}

.sm {
  padding: 0px !important;
  border: none;
  border-bottom: 1px solid #707070;
  border-radius: 0px;
  height: 25px;
  margin-bottom: 10px;
}

.uppercase-input {
  text-transform: uppercase;
}

.lowercase-input {
  text-transform: lowercase;
}

.reporte-novedades-fecha {
  padding: 0px;
  margin: 0px;
  font-weight: 500;
}

.usuario-line {
  border-bottom: 1px dotted #d6d6d6;
  height: 25px;
  line-height: 25px;
  margin-bottom: 4px;
}


.usuario-telefono {
  font-family: monospace;
}

.usuarios-nombres {
  font-size: 20px;
}

.usuarios-datos {
  font-size: 13px;
}

.usuario-info i {
  width: 20px;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
  color: #696969;
}

.label-datos {
  font-family: "Rajdhani", sans-serif;
  font-weight: bold;
}

.modal-body table tr td {
  padding-right: 10px;
}
</style>