<template>
  <div class="fullscreen_bg">
    <title>InformesTJ | v.{{ GLOBAL.VERSION }}</title>
    <div class="form-signin">
      <div class="form-color">
        <h3>Iniciar Sesión</h3>

        <form @submit.prevent="login">
          <input
            v-model="usuario"
            type="text"
            class="form-control"
            placeholder="USUARIO"
            autocomplete="one-time-code"
            autofocus
            style="text-transform: uppercase"
            @blur="usuario = usuario.toUpperCase()"
          />

          <input
            v-model="clave"
            type="password"
            class="form-control"
            placeholder="CONTRASEÑA"
            autocomplete="one-time-code"
          />

          <button class="form-text-button" type="submit">INGRESAR</button>
        </form>
      </div>
      <!-- Transición fade para el mensaje de error -->
      <transition name="fade">
        <div v-if="error" class="alert alert-danger" style="margin-top: 10px">
          {{ mensaje }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import router from "../router";
import axios from "axios";
import GLOBAL from "../Global.js";
import md5 from "md5";

export default {
  name: "HomeView",
  components: {},
  data: function () {
    return {
      GLOBAL,
      options: [],
      tercero: null,
      usuario: "",
      clave: "",
      error: false,
      mensaje: "",
      selected: "A",
    };
  },
  methods: {
    login() {
      // Al hacer clic en "INGRESAR", limpia el error anterior
      this.error = false;
      this.mensaje = "";

      // Ahora intenta loguear
      this.getPersona();
    },

    getPersona() {
      const hostname = window.location.hostname;
      const baseURL = GLOBAL.PATH + `/sinfows/api/v2/login`;

      this.usuario = this.usuario.trim().toUpperCase();

      let request = {
        usuario: this.usuario,
        contrasenia: md5(this.clave),
      };

      var token = btoa(this.usuario + ":" + md5(this.clave));

      axios
        .post(baseURL, request, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          // Guárdalo primero como objeto
          let userObj = response.data;
          // Agrega el token
          userObj.token = token;

          // Opcional: revisa que userObj tenga la estructura deseada
          console.log("Objeto con token:", userObj);

          // Ahora sí, conviértelo a string para almacenarlo en sessionStorage
          sessionStorage.setItem(GLOBAL.SESSION_KEY, JSON.stringify(userObj));

          // Finalmente, redirige
          router.push({ name: "home" });
        })
        .catch((error) => {
          // Si hay error, muestras el mensaje
          this.error = true;
          this.mensaje =
            "Credenciales inválidas. Verifica tu usuario y contraseña.";
        });
    },
  },
};
</script>

<style scoped>
.btn {
  outline: 0;
  border: none;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.form-text-button {
  font-family: "Oswald", sans-serif;
  color: white !important;
  background-color: #009d6b;
  border: none;
  width: 100%;
  padding: 10px;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.form-text-button:active {
  background-color: #006d4b;
}

.form-label {
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  color: #ea592c;
}

.fullscreen_bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: top right;
  background-repeat: repeat;
  background-color: #0054ba;
}

.form-color {
  background: rgb(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  padding: 40px 20px 20px 20px;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.form-signin {
  max-width: 400px;
  position: relative;
  margin: 0px auto;

  top: 18%;
  width: 90%;

  box-sizing: border-box;
}

.form-signin .form-signin-heading,
.form-signin {
  margin-bottom: 10px;
}

.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: none;
  border-left-style: solid;
  border-color: #000;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-style: none;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-color: rgb(0, 0, 0);
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.form-signin-heading {
  color: rgb(84, 84, 84);
  text-align: center;
  font-size: 30px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
