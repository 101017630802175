<template>
  <div>
    <HeaderView />
    <main>
      <router-view />
    </main>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import HeaderView from '@/components/HeaderView.vue'; 

export default {
  components: {
    HeaderView,
    //    Footer
  }
};
</script>