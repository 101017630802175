<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import DefaultLayout from './layouts/DefaultLayout.vue';

export default defineComponent({
  components: {
    DefaultLayout
  },
  setup() {
    const route = useRoute();

    const layout = computed(() => {
      if (route.meta.layout === 'default') {
        return 'DefaultLayout';
      }
      return 'div';
    });

    return { layout };
  }
});
</script>
