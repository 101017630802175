<template>
  <div class="layout">
    <table width="100%">
      <tr>
        <td width="100px">
          <!-- Botón Calendario -->
          <button class="btn btn-success" @click="abrirDatosBasicos()">
            <i class="fa fa-plus"></i>
            Nuevo
          </button>
        </td>
        <td>
          <input
            class="search-input"
            type="text"
            v-model="searchTerm"
            placeholder="Buscar publicador por nombre..."
          />
        </td>
      </tr>
    </table>
    <br />

    <!-- Listado de publicadores -->
    <div class="publicadores-container">
      <div
        v-for="pub in filteredPublicadores"
        :key="pub.id"
        class="publicador-card"
        :class="{
          ok: getInformeStatus(pub) === 'present',
          'not-present': getInformeStatus(pub) === 'noPresent',
          waiting: getInformeStatus(pub) === 'waiting',
        }"
      >
        <!-- Sección izquierda: nombre y estado -->
        <div class="publicador-info">
          <div class="publicador-nombre">
            <span class="link-editar" @click="cargarPublicador(pub.id)"
              >G{{ pub.grupos_id }} - {{ pub.nombres }}
            </span>
          </div>

          <!-- Mostramos un texto distinto según el estado -->
          <div
            class="publicador-estado"
            v-if="getInformeStatus(pub) === 'present'"
          >
            Informe entregado <span class="check">✓</span>
          </div>
          <div
            class="publicador-estado"
            v-else-if="getInformeStatus(pub) === 'waiting'"
          >
            Aún no hay informe
          </div>
          <div class="publicador-estado" v-else>
            No participó <span class="cross">x</span>
          </div>
        </div>

        <!-- Sección derecha: íconos (botones) -->
        <div class="btn-group">
          <!-- Botón Calendario -->
          <button class="btn btn-success" @click="abrirModalInforme(pub)">
            <i class="fa fa-calendar"></i>
          </button>
          <!-- Botón Usuario (de ejemplo) -->
          <button class="btn btn-warning" @click="cargarPublicador(pub.id)">
            <i class="fa fa-user"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Usamos la directiva v-if o v-show para mostrar/ocultar el modal -->
    <div class="modal-custom" v-if="showModalInforme">
      <div class="modal-content">
        <div class="modal-title">
          {{ selectedPub?.nombres }}
        </div>
        <div
          class="modal-subtitle"
          v-if="selectedPub && selectedPub.pregular === 'S'"
        >
          PRECURSOR REGULAR
        </div>

        <table width="100%">
          <tr>
            <td width="33%">
              <div class="form-group">
                <label>Año</label>
                <input type="number" v-model="informe.ano" />
              </div>
            </td>
            <td>
              <div class="form-group">
                <label>Mes</label>
                <select v-model="informe.mes">
                  <!-- Recorremos el array months -->
                  <option v-for="m in months" :key="m.value" :value="m.value">
                    {{ m.label }}
                  </option>
                </select>
              </div>
            </td>
            <td width="33%">
              <div class="form-group">
                <label>A. Servicio</label>
                <!-- Se podría poner 'readonly' si no quieres que el usuario lo cambie manualmente -->
                <input
                  type="number"
                  :value="informeServicio"
                  readonly
                  disabled
                />
              </div>
            </td>
          </tr>
        </table>

        <table>
          <tr>
            <td width="30%">
              <div class="form-group">
                <label>¿Participo?</label>
                <select v-model="informe.informo">
                  <option disabled selected></option>
                  <option value="S">Sí</option>
                  <option value="N">No</option>
                </select>
              </div>
            </td>
            <td>
              <div
                class="form-group"
                v-if="
                  selectedPub &&
                  informe.informo == 'S' &&
                  selectedPub.regular !== 'S'
                "
              >
                <label>¿Fue precursor auxiliar?</label>
                <select v-model="informe.pauxiliar">
                  <!-- v-model "auxiliar" -->
                  <option disabled selected></option>
                  <option value="S">Sí</option>
                  <option value="N">No</option>
                </select>
              </div>
            </td>
          </tr>
        </table>

        <table width="100%">
          <tr>
            <td width="50%">
              <div
                class="form-group"
                v-if="
                  informe.informo == 'S' &&
                  (informe.pregular === 'S' || informe.pauxiliar === 'S')
                "
              >
                <label>Horas</label>
                <input type="number" v-model="informe.horas" />
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div
                class="form-group"
                v-if="
                  informe.informo == 'S'
                "
              >
                <label>Cursos</label>
                <input type="number" v-model="informe.cursos" />
              </div>
            </td>
            <td></td>
          </tr>
        </table>

        <div class="form-group">
          <label>Notas</label>
          <textarea v-model="informe.notas"></textarea>
        </div>

        <div class="modal-alerta">
          {{ informe.mensaje }}
        </div>

        <!-- Botones de acción -->
        <div class="actions">
          <button @click="cerrarModalInforme">Cancelar</button>
          <button @click="guardarInforme">Guardar</button>
        </div>
      </div>
    </div>

    <!-- Modal para agregar o editar un publicador -->
    <div class="modal-custom" v-if="showDatosBasicos">
      <div class="modal-content">
        <table width="100%">
          <tr>
            <td width="50%"></td>
            <td>
              <div class="form-group">
                <label>Estado</label>
                <select v-model="nuevoPublicador.estado" class="form-control">
                  <option value="A">Activo</option>
                  <option value="I">Inactivo</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="form-group">
                <label>Nombre</label>
                <input
                  type="text"
                  v-model="nuevoPublicador.nombres"
                  class="form-control"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-group">
                <label>Género</label>
                <select v-model="nuevoPublicador.genero" class="form-control">
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
                </select>
              </div>
            </td>
            <td>
              <div class="form-group">
                <label>Grupo</label>
                <select class="form-control" v-model="nuevoPublicador.grupo">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-group">
                <label>Fecha de Nacimiento</label>
                <input
                  type="date"
                  v-model="nuevoPublicador.fecha_nacimiento"
                  class="form-control"
                />
              </div>
              <div class="text-edad">{{ edadPublicador }}&nbsp;</div>
            </td>
            <td>
              <div class="form-group">
                <label>Fecha de Bautismo</label>
                <input
                  type="date"
                  v-model="nuevoPublicador.fecha_bautismo"
                  class="form-control"
                />
              </div>
              <div class="text-edad">{{ edadBautismo }}&nbsp;</div>
            </td>
          </tr>
          <tr>
            <td>
              <div
                v-if="
                  nuevoPublicador.fecha_bautismo != null &&
                  nuevoPublicador.fecha_bautismo != ''
                "
                class="form-group"
              >
                <label>Ungido</label>
                <select v-model="nuevoPublicador.ungido" class="form-control">
                  <option value="N">OTRAS OVEJAS</option>
                  <option value="S">UNGIDO</option>
                </select>
              </div>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>
              <div
                v-if="
                  nuevoPublicador.fecha_bautismo != null &&
                  nuevoPublicador.fecha_bautismo != ''
                "
              >
                <label>Precursor Regular</label>
                <div>
                  <select
                    class="form-control"
                    v-model="nuevoPublicador.regular"
                  >
                    <option value="N">NO</option>
                    <option value="S">SI</option>
                  </select>
                </div>
              </div>
            </td>

            <td>
              <div
                v-if="
                  nuevoPublicador.genero == 'M' &&
                  nuevoPublicador.fecha_bautismo != null &&
                  nuevoPublicador.fecha_bautismo != ''
                "
              >
                <label>Responsabilidad</label>
                <select v-model="cargo" class="form-control">
                  <option value="ninguno">NINGUNA</option>
                  <option value="anciano">ANCIANO</option>
                  <option value="ministerial">MINISTERIAL</option>
                </select>
              </div>
            </td>
          </tr>
        </table>

        <br />
        <br />

        <!-- Botones -->
        <div class="actions">
          <button @click="cerrarDatosBasicos">Cancelar</button>

          <button @click="guardarPublicador">
            {{ editMode ? "Actualizar" : "Guardar" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "../router";
import GLOBAL from "../Global.js";

export default {
  data() {
    return {
      GLOBAL,
      showModalInforme: false,
      publicadores: [],
      person: null,
      // Para el buscador
      searchTerm: "",

      // Para almacenar el publicador seleccionado al dar clic en Calendario
      selectedPub: null,

      // Objeto informe
      informe: {
        personas_id: null,
        ano: 0,
        mes: 0,
        horas: 0,
        cursos: 0,
        notas: "",
        informo: null,
        pauxiliar: null,
        pregular: null,
        // Nota: no agregamos "item" ni "servicio" aquí
        // porque los calcularemos antes de POST.
      },
      months: [
        { value: 1, label: "Enero" },
        { value: 2, label: "Febrero" },
        { value: 3, label: "Marzo" },
        { value: 4, label: "Abril" },
        { value: 5, label: "Mayo" },
        { value: 6, label: "Junio" },
        { value: 7, label: "Julio" },
        { value: 8, label: "Agosto" },
        { value: 9, label: "Septiembre" },
        { value: 10, label: "Octubre" },
        { value: 11, label: "Noviembre" },
        { value: 12, label: "Diciembre" },
      ],
      showDatosBasicos: false,
      editMode: false,
      cargo: "ninguno",
      nuevoPublicador: {
        congregacion: null,
        id: null,
        nombres: "",
        estado: "A",
        grupo: null,
        genero: "",
        fecha_nacimiento: null,
        fecha_bautismo: null,
        capitan: null,
      },
    };
  },
  watch: {
    cargo(newValue) {
      if (newValue === "anciano") {
        this.nuevoPublicador.anciano = "S";
        this.nuevoPublicador.ministerial = null;
      } else if (newValue === "ministerial") {
        this.nuevoPublicador.anciano = null;
        this.nuevoPublicador.ministerial = "S";
      } else {
        this.nuevoPublicador.anciano = null;
        this.nuevoPublicador.ministerial = null;
      }
    },
  },
  created() {
    this.person = JSON.parse(sessionStorage.getItem(GLOBAL.SESSION_KEY)) || {};
    console.log(this.person);
    this.fetchPublicadores();
  },
  computed: {
    // Filtra la lista de publicadores según searchTerm
    filteredPublicadores() {
      if (!this.searchTerm) return this.publicadores;
      const lowerSearch = this.searchTerm.toLowerCase();
      return this.publicadores.filter((pub) =>
        pub.nombres.toLowerCase().includes(lowerSearch)
      );
    },
    informeServicio() {
      if (!this.informe.mes || !this.informe.ano) return this.informe.ano;
      // Chequea si mes es septiembre a diciembre
      return [9, 10, 11, 12].includes(this.informe.mes)
        ? this.informe.ano + 1
        : this.informe.ano;
    },
    edadPublicador() {
      if (!this.nuevoPublicador.fecha_nacimiento) return ""; // Si no hay fecha, no mostrar nada

      const nacimiento = new Date(this.nuevoPublicador.fecha_nacimiento);
      const hoy = new Date();

      let edad = hoy.getFullYear() - nacimiento.getFullYear();

      // Verificar si aún no ha cumplido años en este año
      const mesNacimiento = nacimiento.getMonth();
      const diaNacimiento = nacimiento.getDate();
      const mesActual = hoy.getMonth();
      const diaActual = hoy.getDate();

      if (
        mesActual < mesNacimiento ||
        (mesActual === mesNacimiento && diaActual < diaNacimiento)
      ) {
        edad--; // Resta 1 año si aún no ha cumplido
      }

      return "Edad " + edad + " años ";
    },

    edadBautismo() {
      if (!this.nuevoPublicador.fecha_bautismo) return ""; // Si no hay fecha, no mostrar nada

      const nacimiento = new Date(this.nuevoPublicador.fecha_bautismo);
      const hoy = new Date();

      let edad = hoy.getFullYear() - nacimiento.getFullYear();

      // Verificar si aún no ha cumplido años en este año
      const mesNacimiento = nacimiento.getMonth();
      const diaNacimiento = nacimiento.getDate();
      const mesActual = hoy.getMonth();
      const diaActual = hoy.getDate();

      if (
        mesActual < mesNacimiento ||
        (mesActual === mesNacimiento && diaActual < diaNacimiento)
      ) {
        edad--; // Resta 1 año si aún no ha cumplido
      }

      return "lleva... " + edad + " años ";
    },
  },
  methods: {
    /**
     * Carga la lista de publicadores.
     */
    async fetchPublicadores() {
      const baseURL = GLOBAL.PATH + `/sinfows/api/v2/publicadores`;

      try {
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: "Bearer " + this.person.token,
          },
        });
        this.publicadores = response.data;
      } catch (error) {
        console.error("Error al obtener publicadores:", error);
      }
    },

    getInformeStatus(pub) {
      // Si no existen informes o el array está vacío
      if (!pub.informes || pub.informes.length === 0) {
        return "waiting";
      }
      // Si tiene al menos un informe con "informo = 'S'"
      if (pub.informes.some((inf) => inf.informo === "S")) {
        return "present";
      }
      // En caso contrario, significa que tiene informes pero ninguno con 'S'
      return "noPresent";
    },

    /**
     * Verifica si este publicador tiene algún informe con "informo" === "S".
     */
    haPresentadoInforme(pub) {
      return pub.informes && pub.informes.some((inf) => inf.informo === "S");
    },

    /**
     * Abre el modal y setea el publicador seleccionado y datos por defecto.
     */
    abrirModalInforme(pub) {
      this.selectedPub = pub;

      // Obtenemos la fecha actual
      const now = new Date();
      // Ejemplo: si hoy es 2025-01-15
      // getFullYear() => 2025, getMonth() => 0 (enero), +1 => 1
      let currentYear = now.getFullYear();
      let currentMonth = now.getMonth() + 1;

      // Restamos 1 mes
      if (currentMonth === 1) {
        // Si es enero, pasamos a diciembre del año anterior
        currentMonth = 12;
        currentYear -= 1;
      } else {
        currentMonth -= 1;
      }

      // Verificamos si "informes" existe y NO está vacío:
      if (this.selectedPub.informes && this.selectedPub.informes.length > 0) {
        // Si el publicador ya tiene al menos un informe,
        // tomamos el PRIMERO del array (o el que requieras).
        // Ajusta según tu lógica:
        // - el primero guardado (índice 0)
        // - o quizá el último guardado (índice informes.length - 1).

        this.informe = {
          ...this.selectedPub.informes[0], // copiamos para no mutar el original
          // Aun así, podrías actualizar año y mes (siempre que tenga sentido para tu caso)
          ano: currentYear,
          mes: currentMonth,
        };
      } else {
        // Si NO existen informes o el array está vacío,
        // creamos uno nuevo con valores por defecto:
        this.informe = {
          personas_id: pub.id,
          ano: currentYear,
          mes: currentMonth,
          horas: 0,
          cursos: 0,
          notas: "",
          informo: null,
          pregular: pub.regular,
        };
      }

      this.showModalInforme = true;
    },

    /**
     * Cierra el modal y limpia datos si es necesario.
     */
    cerrarModalInforme() {
      this.showModalInforme = false;
      this.selectedPub = null;
    },

    /**
     * Envía el informe al backend (POST /informe).
     */
    async guardarInforme() {
      try {
        this.informe.mensaje = "";

        // Si la persona participó e informe.regular NO es 'S', forzamos horas = 1
        if (this.informe.informo === "S") {
          // 1. Si es precursor regular o auxiliar, exigimos horas > 1
          if (this.informe.pregular === "S" || this.informe.pauxiliar === "S") {
            if (this.informe.horas <= 1) {
              this.informe.mensaje = "Para precursores informe las horas";
              return; // detenemos la ejecución para que corrija
            }
          } else {
            // 2. Si participó y NO es precursor, forzamos horas = 1
            this.informe.horas = 1;
          }
        } else if (this.informe.informo === "N") {
          // 3. Si no participó, 0 horas
          this.informe.horas = 0;
        }

        // Construye la URL de tu API /informe
        const hostname = window.location.hostname;
        const url = GLOBAL.PATH + `/sinfows/api/v2/informe`;

        // Calculamos item y servicio justo antes de POST
        const mes = this.informe.mes;
        const item = ((mes - 9 + 12) % 12) + 1;

        // Obtenemos el año de servicio calculado
        const servicio = this.informeServicio; // computed

        // Construimos el payload final
        const payload = {
          ...this.informe,
          item: item,
          servicio: servicio,
        };

        console.log(payload);

        // Realiza la petición POST
        const response = await axios.post(url, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.person.token,
          },
        });

        // Opcional: podrías actualizar la lista de informes localmente
        // o hacer una llamada para refrescar la data de publicadores:
        // this.fetchPublicadores();

        this.cerrarModalInforme();

        this.$swal({
          title: "Informe enviado!",
          icon: "success",
          timer: 2000, // 3000 milisegundos = 3 segundos
          timerProgressBar: true, // Opcional: muestra una barra de progreso
          showConfirmButton: false, // Oculta el botón de confirmación
        });

        this.fetchPublicadores();
      } catch (error) {
        console.error("Error al guardar informe:", error);
        alert("Ocurrió un error al guardar el informe.");
      } finally {
      }
    },

    abrirDatosBasicos() {
      this.editMode = false;
      this.showDatosBasicos = true;
      this.nuevoPublicador = {
        id: null,
        nombres: "",
        estado: "A",
        grupos_id: null,
        genero: "",
        congregacion: this.person.congregacion || null,
      };
    },

    async cargarPublicador(id) {
      try {
        const hostname = window.location.hostname;
        const url = GLOBAL.PATH + `/sinfows/api/v2/publicadores`;

        // Obtenemos la fecha actual
        const now = new Date();
        // Ejemplo: si hoy es 2025-01-15
        // getFullYear() => 2025, getMonth() => 0 (enero), +1 => 1
        let currentYear = now.getFullYear();
        let currentMonth = now.getMonth() + 1;

        // Restamos 1 mes
        if (currentMonth === 1) {
          // Si es enero, pasamos a diciembre del año anterior
          currentMonth = 12;
          currentYear -= 1;
        } else {
          currentMonth -= 1;
        }

        const response = await axios.get(
          url + `/${id}/${currentYear}/${currentMonth}`
        );
        this.nuevoPublicador = response.data;

        // Determinar cuál radio debe estar seleccionado
        if (this.nuevoPublicador.anciano === "S") {
          this.cargo = "anciano";
        } else if (this.nuevoPublicador.ministerial === "S") {
          this.cargo = "ministerial";
        } else {
          this.cargo = "ninguno";
        }

        this.editMode = true;
        this.showDatosBasicos = true;
        this.fetchPublicadores();
      } catch (error) {
        console.error("Error al cargar publicador:", error);
        alert("Error al cargar el publicador");
      }
    },

    cerrarDatosBasicos() {
      this.showDatosBasicos = false;
    },

    async guardarPublicador() {
      const hostname = window.location.hostname;
      const url = GLOBAL.PATH + `/sinfows/api/v2/publicadores`;

      try {
        let response;
        if (this.editMode) {
          response = await axios.put(
            `${url}/${this.nuevoPublicador.id}`,
            this.nuevoPublicador
          );
        } else {
          response = await axios.post(url, this.nuevoPublicador, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.person.token,
            },
          });
        }
        this.cerrarDatosBasicos();

        this.$swal({
          title: "Publicador guardado correctamente",
          icon: "success",
          timer: 1500, // 3000 milisegundos = 3 segundos
          timerProgressBar: true, // Opcional: muestra una barra de progreso
          showConfirmButton: false, // Oculta el botón de confirmación
        });

        this.fetchPublicadores();
      } catch (error) {
        console.error("Error al guardar publicador:", error);
        alert("Error al guardar publicador");
      }
    },
  },
};
</script>

<style scoped>
/* Contenedor principal: organiza las tarjetas */
.publicadores-container {
  /* Habilitamos Flexbox y que pueda partirse en nuevas filas */
  display: flex;
  flex-wrap: wrap;
  /* Espacio entre tarjetas */
  gap: 10px;

  /* Tamaño máximo y centrado en la página */
  max-width: 1200px;
  margin: 0 auto;

  /* Opcional: algo de padding interno para no quedar pegado a los bordes */
  padding: 0 15px;

  /* Centra las tarjetas dentro del contenedor */
  justify-content: center;
}

.publicador-card {
  background-color: #bababa;
  border-radius: 8px;
  padding: 10px 14px;

  /* 
    Para 3 columnas en pantallas anchas.
    (El -20px es por el gap total de 10px a cada lado si gustas afinarlo)
  */
  flex: 0 0 calc(33.333% - 20px);
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Cuando el ancho baje, cambiamos a 2 columnas */
@media (max-width: 992px) {
  .publicador-card {
    flex: 0 0 calc(50% - 20px);
  }
}

/* Para móviles o pantallas muy pequeñas, 1 columna */
@media (max-width: 576px) {
  .publicador-card {
    flex: 0 0 calc(100% - 20px);
  }
}

.ok {
  background-color: #aff591;
  /* por ejemplo, verde claro */
}

/* Estado: no presentó informe */
.not-present {
  background-color: #f2a6a6;
  /* por ejemplo, rojo claro */
}

/* Estado: esperando crear informe (no hay informes) */
.waiting {
  background-color: #c2c2c2;
  /* por ejemplo, amarillo claro */
}

@media (max-width: 992px) {
  .publicador-card {
    flex: 0 0 calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .publicador-card {
    flex: 0 0 calc(100% - 20px);
  }
}

/* Sección izquierda */
.publicador-info {
  display: flex;
  flex-direction: column;
}

.publicador-nombre {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 4px;

  /* Ajusta la altura en función de tu line-height */
  line-height: 1.4em;
  height: 2.8em;
  /* 1.4em * 2 líneas = 2.8em */

  /* Evita que el texto se salga */
  overflow: hidden;
  /* Permite que el texto haga salto de línea */
  white-space: normal;
}

.publicador-estado {
  font-size: 0.85rem;
  color: #444;
}

.check {
  color: green;
  font-weight: bold;
}

.cross {
  color: red;
  font-weight: bold;
}

/* Sección derecha: íconos */
.publicador-iconos i {
  font-size: 1.2rem;
  margin-left: 8px;
  cursor: pointer;
}

.ml-2 {
  margin-left: 0.5rem;
}

/* Búsqueda */
.search-container {
  max-width: 1200px;
  margin: 0 auto 20px auto;
  padding: 0 15px;
}

.search-input {
  width: 100%;
  padding: 8px 12px;
  font-size: 0.95rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Fondo semi-transparente */
  background: rgba(0, 0, 0, 0.5);
  /* Centra el contenido */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* para asegurar que quede sobre el resto */
}

.modal-content {
  background: #fff;
  padding: 20px;
  width: 400px;
  /* ajusta según tu gusto */
  border-radius: 8px;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
}

.modal-subtitle {
  font-size: 15px;
  color: red;
}

.modal-alerta {
  background-color: yellow;
  color: red;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Ejemplo de estilos en inputs */
.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Botones del modal */
.actions {
  display: flex;
  justify-content: space-between;
  /* Separa los botones en los extremos */
  align-items: center;
  /* Alinea verticalmente, si lo necesitas */
  gap: 10px;
  /* Espacio entre botones (opcional) */
}

.actions button {
  padding: 8px 12px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.actions button:nth-child(1) {
  background-color: #dc3545;
  /* verde */
  color: #fff;
}

.actions button:nth-child(2) {
  background-color: #28a745;
  /* rojo */
  color: #fff;
}

input:disabled {
  background-color: #bbbbbb;
  color: #0f0f0f;
  cursor: not-allowed;
}

.link-editar {
  color: blue;
  cursor: pointer;
}
.text-edad {
  color: #444;
  font-weight: bold;
  text-align: right;
  font-size: 11px;
  margin-top: -5px;
  margin-bottom: 5px;
}
</style>
