<template>
  <nav class="md-tabs-menu">
    <ul>
      <!-- Iteramos las opciones filtradas -->
      <li v-for="item in filteredMenu" :key="item.to">
        <!-- Cada enlace es una "Material Tab" -->
        <router-link :to="item.to" class="md-tab">
          <!-- Ícono, si lo deseas -->
          <i :class="item.icon" class="md-tab-icon"></i>
          <!-- Texto -->
          <span class="md-tab-label">
            {{ item.descripcion }}
          </span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import GLOBAL from '../Global.js';

export default {
  name: 'MenuView',
  computed: {
    filteredMenu() {
      // Lee la info de sesión para obtener el perfil
      const sessionData = sessionStorage.getItem(GLOBAL.SESSION_KEY);
      const user = sessionData ? JSON.parse(sessionData) : null;

      // Si no hay usuario, no se muestra nada
      if (!user) {
        return [];
      }

      const perfilUsuario = parseInt(user.perfil_id);

      // Filtra las opciones que permitan este perfil
      return GLOBAL.MENU_ITEMS.filter((menuItem) =>
        menuItem.allowedProfiles.includes(perfilUsuario)
      );
    },
  },
};
</script>

<style scoped>
/* Contenedor principal de la barra de pestañas */
.md-tabs-menu {
  display: flex;
  justify-content: flex-start; 
  background-color: #ffffff;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  padding: 0 8px; /* Menos espacio */
  overflow-x: auto;
}

/* Lista de pestañas en fila */
.md-tabs-menu ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* Cada tab (li) */
.md-tabs-menu li {
  margin: 0;
}

/* Estilo base del enlace tab */
.md-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  /* Medidas típicas de un tab en Material Design */
  min-width: 80px;
  height: 48px;
  padding: 0 16px;

  /* Tipografía Material */
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: #757575;
  border-bottom: 2px solid transparent;

  /* Animaciones de color */
  transition:
    background-color 0.2s ease,
    color 0.2s ease,
    border-color 0.2s ease;
}

/* Ícono dentro de la tab */
.md-tab-icon {
  margin-right: 8px;
  font-size: 18px;
  display: inline-block;
}

/* Label de la tab (opcional si quieres separar estilo) */
.md-tab-label {
  display: inline-block;
}

/* Efecto hover: un fondo suave */
.md-tab:hover {
  background-color: rgba(98, 0, 238, 0.08); /* color primario (morado) con opacidad */
}

/* Cuando la ruta está activa, Vue Router pone .router-link-active */
.router-link-active.md-tab {
  color: #6200ee; /* color primario (morado) */
  border-bottom: 2px solid #6200ee;
}
</style>
