<template>
  <div>
    <div class="btn-group btn-sm ">

      <button class="btn btn-sm btn-primary" @click="toggleVisibility">
        {{ isVisible ? 'Ocultar' : 'Mostrar' }} filtros
      </button>

      <button class="btn btn-sm btn-info" @click="cargaTarjetas">
        Cargar tarjetas
      </button>

      <button class="btn btn-sm btn-secondary" @click="printArea('printableArea')">
        Imprimir
      </button>

    </div>
    <table class="nopadding" width="100%">
      <tr>
        <transition name="fade">
          <td v-show="isVisible" class="animated-td" width="300px" style="vertical-align: top;">
            <div class="toolbox">

              <div class="filtro">
                <label><input type="radio" name="precursores" value="S" v-model="regulares"> Precursores regulares</label>
              </div>

              <div class="filtro">
                <label><input type="radio" name="precursores" value="N"  v-model="regulares"> No precursores regulares</label>
              </div>

              <div class="filtro">
                <label><input type="checkbox"> Varones</label>
              </div>

              <div class="input-group mb-3">
                <input type="text" class="form-control inputjw" v-model="searchQuery">
                <div class="input-group-append " @click="fetchPublishers">
                  <span class="input-group-text btnjw">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </span>
                </div>
              </div>



              <div class="cajadatos">
                <div v-for="person in filteredRegistros" :key="person.id" :value="person.id"
                  @click="addToSelectedList(person)" style="cursor: pointer;">
                  {{ person.nombres }}
                </div>
              </div>

              <br />
              <div>Publicadores</div>

              <div>
                <div v-for="person in selectedList" :key="person.id">
                  {{ person.nombres }}
                  <button class="btn btn-sm bg-gray" @click="removeFromSelectedList(person)">
                    <i class="fa-regular fa-circle-xmark"></i>
                  </button>
                </div>
              </div>

              <br />




            </div>
          </td>
        </transition>



        <td style="vertical-align: top;">
          <div id="printableArea" class="workspace">
            &nbsp;
            <div v-if="formattedSelectedList.length" style="min-height: 80hv;">
              <PersonCard :personas="formattedSelectedList" />
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
import router from "../router";
import GLOBAL from "../Global.js";
import PersonCard from '../components/PersonCard.vue';

export default {
  components: {
    PersonCard
  },
  data() {
    return {
      GLOBAL,
      loading: false,
      isVisible: true,
      tarjetas: [],
      publicadores: [],
      selectedPersonId: null,
      searchQuery: '',  // Asegúrate de definir searchQuery aquí
      selectedList: [],  // Lista para almacenar los registros seleccionados
      formattedSelectedList: [],  // Lista formateada para PersonCard
      person: JSON.parse(sessionStorage.getItem(GLOBAL.SESSION_KEY)),
      regulares: 'N'
    }
  },

  created() {
    this.setDate();
    this.cargaPublicadores();
  },

  computed: {
    selectedPerson() {
      return this.publicadores.find((person) => person.id === this.selectedPersonId);
    },
    filteredRegistros() {
      if (!this.searchQuery) {
        return this.publicadores;
      }
      const query = this.searchQuery.toLowerCase();
      return this.publicadores.filter((person) =>
        person.nombres.toLowerCase().includes(query)
      );
    },
  },

  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    setDate() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth(); // 0-11, so January is 0
      if (currentMonth === 0) { // January
        this.ano = currentYear - 1;
        this.mes = 12; // December
      } else {
        this.ano = currentYear;
        this.mes = currentMonth; // Previous month (0-11, so January is 0)
      }
      this.servicio = (this.mes >= 9 ? this.ano + 1 : this.ano);
    },

    async cargaPublicadores() {
      try {
        let url = GLOBAL.PATH
          + "/informe"
          + '?contiene=' + this.filtro_mensaje
          + '&estado=' + this.filtro_estado
          + '&ano=' + this.ano
          + '&mes=' + this.mes;

        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Authorization": "Bearer " + this.person.token
          }
        });

        this.publicadores = response.data;

      } catch (error) {
        console.error('Error al cargar registros:', error);
      }
    },

    async cargaTarjetas() {
      // Obtener IDs de los registros seleccionados
      const selectedIds = this.selectedList.map(person => person.id).join(','); 
      const regularesSN = this.regulares
      try {

        // Incluir los IDs en la URL de la petición
        let url = `${GLOBAL.API_TARJETAS}?filtro=${selectedIds}&regulares=${regularesSN}`;

        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Authorization": "Bearer " + this.person.token
          }
        });

        // Formatear los registros seleccionados
        this.formattedSelectedList = this.formatPersonas(response.data);

      } catch (error) {
        console.error('Error al cargar registros:', error);
      }
    },

    getMonthName(month) {
      const months = [
        "ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
        "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"
      ];
      return months[month - 1];
    },

    formatPersonas(registros) {
      const personas = {};
      registros.forEach(persona => {
        if (!(persona.id in personas)) {
          personas[persona.grupos_id+'-'+persona.id] = {
            nombres: persona.nombres,
            grupos_id: persona.grupos_id,
            fecha_nacimiento: persona.fecha_nacimiento,
            edad_actual: persona.edad_actual,
            fecha_bautismo: persona.fecha_bautismo,
            bautismo: persona.bautismo,
            genero: persona.genero,
            anciano: persona.anciano,
            ministerial: persona.ministerial,
            regular: persona.regular,
            informes: {}
          };
        }

        if (persona.informes != null) {
          persona.informes.forEach(informe => {
            const ano = informe.servicio;
            const mmes = informe.mmes;

            if (!(ano in personas[persona.grupos_id+'-'+persona.id].informes)) {
              const defecto = { 
                ano: informe.servicio, 
                cursos: 0, horas: 0, informo: "N", mes: 1, pauxiliar: "N", 
                pregular: "N", publicaciones: 0, revisitas: 0, 
                servicio: informe.servicio, 
                videos: 0 }
              const meses = {}
              meses['Septiembre'] = defecto
              meses['Octubre'] = defecto
              meses['Noviembre'] = defecto
              meses['Diciembre'] = defecto
              meses['Enero'] = defecto
              meses['Febrero'] = defecto
              meses['Marzo'] = defecto
              meses['Abril'] = defecto
              meses['Mayo'] = defecto
              meses['Junio'] = defecto
              meses['Julio'] = defecto
              personas[persona.grupos_id+'-'+persona.id].informes[ano] = []
              personas[persona.grupos_id+'-'+persona.id].informes[ano].meses = meses
            }
            personas[persona.grupos_id+'-'+persona.id].informes[ano].meses[mmes] = informe;
          });
        }
      });
      console.log(personas);
      return Object.values(personas);
    },

    onPersonChange() {
      console.log('Selected Person ID:', this.selectedPersonId);
    },

    addToSelectedList(person) {
      // Añadir a la lista solo si no está ya en ella
      if (!this.selectedList.includes(person)) {
        this.selectedList.push(person);
      }
    },

    removeFromSelectedList(person) {
      // Remover el registro de la lista
      this.selectedList = this.selectedList.filter(p => p.id !== person.id);
    },

    async printArea(areaId) {
      // Obtener el contenido del área a imprimir
      const printContents = document.getElementById(areaId).innerHTML;

      var printWindow = window.open('', '', '');
      printWindow.document.write('<html>');
      printWindow.document.write('<head>');
      printWindow.document.write('<link rel="stylesheet" href="dist/css/alt/adminlte.light.css">');
      printWindow.document.write('<link rel="stylesheet" href="dist/css/tarjeta.css">');
      printWindow.document.write('</head>');
      printWindow.document.write('<body>');
      printWindow.document.write(printContents);
      printWindow.document.write('</body></html>');
      printWindow.document.close();

      // Esperar hasta que todos los recursos se hayan cargado
      printWindow.onload = function () {
        printWindow.print();
      };
    }

  }
};
</script>

<style scoped>
.workspace {
  background-color: lightgray;
  height: 80vh;
  overflow-y: scroll;
}

.nopadding tr td {
  margin: 0px;
  padding: 0px;
}

.cajadatos {
  height: 150px;
  background-color: rgb(183, 183, 183);
  overflow-y: scroll;
}

.selected-list {
  margin-top: 20px;
}

.toolbox {
  padding: 8px;
  font-size: 14px;
  line-height: 18px;
}

.filtro {
  margin-top: 3px;
}

.inputjw {
  border: 1px solid #007bff;
  height: 30px
}

.btnjw {
  border: 1px solid #007bff;
  height: 30px;
  background-color: #007bff;
  color: white;
}
</style>
