import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import GestionUsuariosView from '../views/GestionUsuariosView.vue'
import ResumenView from '../views/ResumenView.vue'
import TarjetasView from '../views/TarjetasView.vue'


const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: { layout: 'default' }
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: { 
      layout: 'default',
      allowedProfiles: [1, 2, 5, 8]  // Perfiles con acceso a /home
    }
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: GestionUsuariosView,
    meta: { 
      layout: 'default',
      allowedProfiles: [1, 2]       // Solo perfiles 1 y 2
    }
  },
  {
    path: '/resumen',
    name: 'resumen',
    component: ResumenView,
    meta: { 
      layout: 'default',
      allowedProfiles: [1, 2]       // Solo perfiles 1 y 2
    }
  },
  {
    path: '/tarjetas',
    name: 'tarjetas',
    component: TarjetasView,
    meta: { 
      layout: 'default',
      allowedProfiles: [1, 2]       // Solo perfiles 1 y 2
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // 1. Verifica si el usuario está logueado
  const sessionData = sessionStorage.getItem("sinfows.persona");
  const user = sessionData ? JSON.parse(sessionData) : null;

  // Si no está logueado y no vas a login, redirige a login
  if (to.name !== 'login' && !user) {
    return next({ name: 'login' });
  }

  // 2. Chequea si la ruta tiene meta.allowedProfiles
  const allowedProfiles = to.meta.allowedProfiles;
  if (allowedProfiles && user) {
    // 3. Si el perfil del usuario NO está en esa lista, redirige o muestra error
    if (!allowedProfiles.includes(parseInt(user.perfil_id))) {
      // Por ejemplo, podrías redirigir a Home o a Login
      alert("No tienes acceso a esta ruta");
      return next({ name: 'home' });
    }
  }

  // 4. Si pasa todas las validaciones, next()
  next();
});


export default router;
